import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const Stack = () => {
    return (
        <Layout>
            <Link className='text-gray-400 hover:text-gray-300' to='/'>← Back</Link>
			<h1 className='text-3xl mt-8'>Stack</h1>
            <p className='my-4'>A list of tools I'm using. More to come.</p>
            <div>
                <h3 className='text-xl font-semibold mt-8 mb-4'>Hardware</h3>
                <ul className='list-disc list-inside'>
                    <li className='my-2'>13" Macbook Pro (2019)</li>
                    <li className='my-2'>27" Dell U2720Q</li>
                    <li className='my-2'>Keychron K2 Mechanical Keyboard</li>
                </ul>
                <h3 className='text-xl font-semibold mt-8 mb-4'>Software</h3>
                <ul className='list-disc list-inside'>
                    <li className='my-2'>VSCode</li>
                    <li className='my-2'>Figma</li>
                    <li className='my-2'>Contrast Extension</li>
                    <li className='my-2'>Github Desktop</li>
                    <h3 className='text-xl font-semibold mt-8 mb-4'>Frameworks &amp; Languages</h3>
                    <li className='my-2'>Typescript</li>
                    <li className='my-2'>React</li>
                    <li className='my-2'>GraphQL</li>
                    <li className='my-2'>Gatsby</li>
                    <li className='my-2'>Git</li>
                    <li className='my-2'>Netlify</li>
                    <li className='my-2'>TailwindCSS</li>
                    <li className='my-2'>Polkadot.js</li>
                </ul>
            </div>
        </Layout>
    )
}

export default Stack;