/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div
      className='mx-auto px-4 md:px-0 mt-8 md:mt-24 max-w-screen-sm'
    >
      <main className='pb-16'>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
