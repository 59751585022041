import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className='pb-16'>
            <hr className='w-full border-1 border-gray-800 my-16'/>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 pb-16'>
                <div className='flex flex-col space-y-4'>
                    <Link
                        to='/'
                    >
                        Home
                    </Link>
                </div>
                <div className='flex flex-col space-y-4'>
                    <a 
                        className={``}
                        href='https://twitter.com/michelerler'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Twitter
                    </a>
                    <a 
                        className={``}
                        href='https://github.com/erler'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Github
                    </a>
                    <a 
                        className={``}
                        href='https://warpcast.com/erler'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Farcaster
                    </a>
                    <a 
                        className={``}
                        href='https://sublime.app/michelerler'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Sublime
                    </a>
                </div>
                <div className='flex flex-col space-y-4'>
                    <Link
                        to='/readings'
                    >
                        Readings
                    </Link>
                    <Link
                        to='/stack'
                    >
                        Stack
                    </Link>
                </div>
            </div>
            <div className='text-xs text-gray-400'>© {new Date().getFullYear()}, Michel Erler</div>      
        </footer>
    )
}

export default Footer;